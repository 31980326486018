import React from "react";
import { createInertiaApp } from "@inertiajs/react";
import { createRoot } from "react-dom/client";

interface PageModule {
  default: React.ComponentType;
}

createInertiaApp({
  resolve: async (name: string) => {
    const pages = import.meta.glob<PageModule>("../Pages/**/*.tsx");
    const page = await pages[`../Pages/${name}.tsx`]();
    return page.default;
  },
  setup({ el, App, props }) {
    createRoot(el).render(<App {...props} />);
  },
});
